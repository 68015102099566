import classNames from "classnames";
import {HTMLProps} from "react";
import {P} from "../../typography";
import s from "./InputLabel.module.css";


type Props = {
    title: string;
    message?: string;
    titleColor?: "dark-gray-p200";
    error?: string | null;
    isRequired?: boolean;
} & HTMLProps<HTMLLabelElement>;

export const InputLabel = (props: Props) => {
    const {className, title, titleColor, message, error, children, isRequired, ...defaultProps} = props;
    return (
        <label className={classNames(s.container, className)} {...defaultProps}>
            <P className={s.title} variant="Paragraph/Default/p" color={titleColor}>
                {title}
                {isRequired && <span className={s.requiredMark}> *</span>}
            </P>
            {children}
            {error && <P variant="Paragraph/Small/p" color="dark-status-danger-d300">{error}</P>}
            {message && !error && <P variant="Paragraph/Small/p" color="dark-gray-p100">{message}</P>}
        </label>
    );
};
