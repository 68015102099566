import createSvgIcon from "./utils";

const IconCalendar16 = createSvgIcon((
    <>
        <path
            d="M5.33333 3.8335C5.06 3.8335 4.83333 3.60683 4.83333 3.3335V1.3335C4.83333 1.06016 5.06 0.833496 5.33333 0.833496C5.60667 0.833496 5.83333 1.06016 5.83333 1.3335V3.3335C5.83333 3.60683 5.60667 3.8335 5.33333 3.8335Z"
            stroke="transparent"/>
        <path
            d="M10.6667 3.8335C10.3933 3.8335 10.1667 3.60683 10.1667 3.3335V1.3335C10.1667 1.06016 10.3933 0.833496 10.6667 0.833496C10.94 0.833496 11.1667 1.06016 11.1667 1.3335V3.3335C11.1667 3.60683 10.94 3.8335 10.6667 3.8335Z"
            stroke="transparent"/>
        <path
            d="M5.66667 9.66683C5.58 9.66683 5.49333 9.64684 5.41333 9.61351C5.32667 9.58018 5.26 9.53349 5.19333 9.4735C5.07333 9.34683 5 9.18017 5 9.00017C5 8.9135 5.02 8.82683 5.05333 8.74683C5.08667 8.66683 5.13333 8.59351 5.19333 8.52684C5.26 8.46684 5.32667 8.42016 5.41333 8.38683C5.65333 8.28683 5.95333 8.34017 6.14 8.52684C6.26 8.65351 6.33333 8.82683 6.33333 9.00017C6.33333 9.04017 6.32667 9.08684 6.32 9.13351C6.31333 9.17351 6.3 9.2135 6.28 9.2535C6.26667 9.2935 6.24667 9.3335 6.22 9.3735C6.2 9.40683 6.16667 9.44016 6.14 9.4735C6.01333 9.5935 5.84 9.66683 5.66667 9.66683Z"
            stroke="transparent"/>
        <path
            d="M8 9.66683C7.91333 9.66683 7.82667 9.64684 7.74667 9.6135C7.66 9.58017 7.59333 9.53349 7.52667 9.47349C7.40667 9.34682 7.33333 9.18016 7.33333 9.00016C7.33333 8.91349 7.35333 8.82682 7.38667 8.74682C7.42 8.66682 7.46667 8.5935 7.52667 8.52683C7.59333 8.46683 7.66 8.42015 7.74667 8.38682C7.98667 8.28015 8.28667 8.34017 8.47333 8.52683C8.59333 8.6535 8.66667 8.82683 8.66667 9.00016C8.66667 9.04016 8.66 9.08683 8.65333 9.1335C8.64667 9.1735 8.63333 9.2135 8.61333 9.2535C8.6 9.2935 8.58 9.33349 8.55333 9.37349C8.53333 9.40683 8.5 9.44015 8.47333 9.47349C8.34667 9.59349 8.17333 9.66683 8 9.66683Z"
            stroke="transparent"/>
        <path
            d="M10.3333 9.66683C10.2467 9.66683 10.16 9.64684 10.08 9.6135C9.99333 9.58017 9.92667 9.53349 9.86 9.47349C9.83333 9.44015 9.80667 9.40683 9.78 9.37349C9.75333 9.33349 9.73333 9.2935 9.72 9.2535C9.7 9.2135 9.68667 9.1735 9.68 9.1335C9.67333 9.08683 9.66667 9.04016 9.66667 9.00016C9.66667 8.82683 9.74 8.6535 9.86 8.52683C9.92667 8.46683 9.99333 8.42015 10.08 8.38682C10.3267 8.28015 10.62 8.34017 10.8067 8.52683C10.9267 8.6535 11 8.82683 11 9.00016C11 9.04016 10.9933 9.08683 10.9867 9.1335C10.98 9.1735 10.9667 9.2135 10.9467 9.2535C10.9333 9.2935 10.9133 9.33349 10.8867 9.37349C10.8667 9.40683 10.8333 9.44015 10.8067 9.47349C10.68 9.59349 10.5067 9.66683 10.3333 9.66683Z"
            stroke="transparent"/>
        <path
            d="M5.66667 12.0002C5.58 12.0002 5.49333 11.9802 5.41333 11.9468C5.33333 11.9135 5.26 11.8668 5.19333 11.8068C5.07333 11.6802 5 11.5068 5 11.3335C5 11.2468 5.02 11.1602 5.05333 11.0802C5.08667 10.9935 5.13333 10.9202 5.19333 10.8602C5.44 10.6135 5.89333 10.6135 6.14 10.8602C6.26 10.9868 6.33333 11.1602 6.33333 11.3335C6.33333 11.5068 6.26 11.6802 6.14 11.8068C6.01333 11.9268 5.84 12.0002 5.66667 12.0002Z"
            stroke="transparent"/>
        <path
            d="M8 12.0002C7.82667 12.0002 7.65333 11.9268 7.52667 11.8068C7.40667 11.6802 7.33333 11.5068 7.33333 11.3335C7.33333 11.2468 7.35333 11.1602 7.38667 11.0802C7.42 10.9935 7.46667 10.9202 7.52667 10.8602C7.77333 10.6135 8.22667 10.6135 8.47333 10.8602C8.53333 10.9202 8.58 10.9935 8.61333 11.0802C8.64667 11.1602 8.66667 11.2468 8.66667 11.3335C8.66667 11.5068 8.59333 11.6802 8.47333 11.8068C8.34667 11.9268 8.17333 12.0002 8 12.0002Z"
            stroke="transparent"/>
        <path
            d="M10.3333 12.0002C10.16 12.0002 9.98667 11.9268 9.86 11.8068C9.8 11.7468 9.75333 11.6735 9.72 11.5869C9.68667 11.5069 9.66667 11.4202 9.66667 11.3335C9.66667 11.2469 9.68667 11.1602 9.72 11.0802C9.75333 10.9935 9.8 10.9202 9.86 10.8602C10.0133 10.7069 10.2467 10.6335 10.46 10.6802C10.5067 10.6868 10.5467 10.7002 10.5867 10.7202C10.6267 10.7335 10.6667 10.7535 10.7067 10.7802C10.74 10.8002 10.7733 10.8335 10.8067 10.8602C10.9267 10.9869 11 11.1602 11 11.3335C11 11.5069 10.9267 11.6802 10.8067 11.8068C10.68 11.9268 10.5067 12.0002 10.3333 12.0002Z"
            stroke="transparent"/>
        <path
            d="M13.6667 6.56014H2.33333C2.06 6.56014 1.83333 6.33347 1.83333 6.06014C1.83333 5.78681 2.06 5.56014 2.33333 5.56014H13.6667C13.94 5.56014 14.1667 5.78681 14.1667 6.06014C14.1667 6.33347 13.94 6.56014 13.6667 6.56014Z"
            stroke="transparent"/>
        <path
            d="M10.6667 15.1668H5.33333C2.9 15.1668 1.5 13.7668 1.5 11.3335V5.66683C1.5 3.2335 2.9 1.8335 5.33333 1.8335H10.6667C13.1 1.8335 14.5 3.2335 14.5 5.66683V11.3335C14.5 13.7668 13.1 15.1668 10.6667 15.1668ZM5.33333 2.8335C3.42667 2.8335 2.5 3.76016 2.5 5.66683V11.3335C2.5 13.2402 3.42667 14.1668 5.33333 14.1668H10.6667C12.5733 14.1668 13.5 13.2402 13.5 11.3335V5.66683C13.5 3.76016 12.5733 2.8335 10.6667 2.8335H5.33333Z"
            stroke="transparent"/>

    </>

), {width: 16, height: 16});

export default IconCalendar16;