import {CitizenUserRoute} from "citizens/routes/citizen_user/CitizenUserRoute";
import classNames from "classnames";
import IconApartment from "common/components/icons/IconApartment";
import IconCalendar28x32 from "common/components/icons/IconCalendar28x32";
import IconDocument35 from "common/components/icons/IconDocument35";
import IconMan from "common/components/icons/IconMan";
import IconNotebook from "common/components/icons/IconNotebook";
import IconPeoples from "common/components/icons/IconPeoples";
import IconRequests35x35 from "common/components/icons/IconRequests35x35";
import IconShield28x32 from "common/components/icons/IconShield28x32";
import {UserStore} from "common/stores";
import {observer} from "mobx-react-lite";
import React, {lazy} from "react";
import {createBrowserRouter, Navigate, useOutlet, useRouteError} from "react-router-dom";
import {DefinedUserRole} from "users/types";
import {RouteError} from "../route";
import {ErrorBoundary} from "./ErrorBoundry";
import s from "./index.module.css";
import {INavigationItem, Navigation} from "./navigation";
import NotFoundRoute from "./NotFoundRoute";
import {ProtectedRoute} from "./ProtectedRoute";
import IconTaskSquare from "../icons/IconTaskSquare";

const Index = observer(() => {
    const outlet = useOutlet();
    return (
        <div className={classNames(s.app)}>
            <Navigation routes={navigationItems}/>
            <ErrorBoundary>
                <React.Suspense>
                    {outlet}
                </React.Suspense>
            </ErrorBoundary>
        </div>
    );
});

const ErrorElement = () => {
    const error = useRouteError();
    return <RouteError message={`Произошла ошибка: ${error?.toString()}`}/>;
}

const canSeeCompanyRoute = (user: UserStore) => {
    if (user.isEngineer) {
        return false;
    }

    if (user.isEtdAdmin) {
        return true;
    }

    return user.accessedCompanies.length > 1;
};

function canSeeSingleCompany(user: UserStore) {
    if (user.accessedCompanies.length !== 1) {
        return false;
    }

    return [
        DefinedUserRole.ADMIN,
        DefinedUserRole.USER,
        DefinedUserRole.DISPATCHER,
    ].includes(user.roleId);
}

const canSeeCompanySubRoute = (user: UserStore) => !user.isEngineer;

const citizenRoutesRole = [DefinedUserRole.ADMIN, DefinedUserRole.ADMIN_ETD];
const exceptEngineerRoles = [
    DefinedUserRole.ADMIN_ETD,
    DefinedUserRole.ADMIN,
    DefinedUserRole.USER,
    DefinedUserRole.DISPATCHER,
];

const Tasks = lazy(() => import("../../../tasks/routes/tasks"));
const TaskDetails = lazy(() => import("../../../tasks/routes/task_details"));
const Companies = lazy(() => import("../../../companies/routes/companies"));
const CompanyDetails = lazy(() => import("../../../companies/routes/company_details"));
const CompanyCreation = lazy(() => import("../../../companies/routes/add_company"));
const Houses = lazy(() => import("../../../houses/routes/house_list"));
const HouseDetails = lazy(() => import("../../../houses/routes/house"));
const Users = lazy(() => import("../../../users/routes/user_list"));
const UserGeoHistory = lazy(() => import("../../../users/routes/user_geo_history"));
const Citizen = lazy(() => import("../../../citizens/routes/citizen"));
const CitizenHouse = lazy(() => import("../../../citizens/routes/citizen_house"));
const CitizenNotificationArchive = lazy(() => import("../../../citizens/routes/notification_archive"));
const CitizenNotificationSend = lazy(() => import("../../../citizens/routes/notification_send"));
const CitizenNotificationDetails = lazy(() => import("../../../citizens/routes/notification_details"));
const ApartmentDetails = lazy(() => import("../../../citizens/routes/apartment_details"));
const IntercomList = lazy(() => import("../../../citizens/routes/intercom_list"));
const IntercomDetails = lazy(() => import("../../../citizens/routes/intercom_details"));
const GateList = lazy(() => import("../../../citizens/routes/gate_list"));
const CameraList = lazy(() => import("../../../citizens/routes/camera_list"));
const CameraDetails = lazy(() => import("../../../citizens/routes/camera_details"));
const Documents = lazy(() => import("../../../tasks/routes/documents"));
const Roles = lazy(() => import("../../../users/routes/roles"));
const Reports = lazy(() => import("../../../reports/routes/reports"));
const Calendar = lazy(() => import("../../../users/routes/calendar"));

export const etdRouter = createBrowserRouter([
    {
        path: "/",
        element: <Index/>,
        errorElement: <ErrorElement/>,
        children: [
            {
                index: true,
                element: <Navigate to="/tasks" replace/>,
            },
            {
                path: "*",
                element: <NotFoundRoute/>,
            },

            {
                path: "calendar",
                element: (
                    <ProtectedRoute
                        element={<Calendar/>}
                        roles={[
                            DefinedUserRole.ADMIN_ETD,
                            DefinedUserRole.ADMIN,
                        ]}/>
                ),
            },

            //Заявки
            {
                path: "tasks",
                element: <Tasks/>,
            },
            {
                path: "tasks/:taskId",
                element: <TaskDetails/>,
            },

            //Пользователи
            {
                path: "users",
                element: <Users/>,
            },
            {
                path: "users/:userId/geo-history",
                element: <ProtectedRoute element={<UserGeoHistory/>}
                                         roles={[DefinedUserRole.ADMIN, DefinedUserRole.ADMIN_ETD]}/>,
            },

            //Дома
            {
                path: "homes",
                element: <Houses/>,
            },
            {
                path: "homes/:houseId",
                element: (
                    <ProtectedRoute
                        element={<HouseDetails/>}
                        roles={exceptEngineerRoles}/>
                ),
            },

            //Админка жителя
            {
                path: "citizen",
                element: (
                    <ProtectedRoute
                        element={<Citizen/>}
                        roles={citizenRoutesRole}/>
                ),
            },
            {
                path: "citizen/houses/:id",
                element: (
                    <ProtectedRoute
                        element={<CitizenHouse/>}
                        roles={citizenRoutesRole}/>
                ),
            },
            {
                path: "citizen/notifications/new",
                element: (
                    <ProtectedRoute
                        element={<CitizenNotificationSend/>}
                        roles={citizenRoutesRole}/>
                ),
            },
            {
                path: "citizen/notifications/:id",
                element: (
                    <ProtectedRoute
                        element={<CitizenNotificationDetails/>}
                        roles={citizenRoutesRole}/>
                ),
            },
            {
                path: "citizen/houses/:houseId/apartments/:apartment",
                element: (
                    <ProtectedRoute
                        element={<ApartmentDetails/>}
                        roles={citizenRoutesRole}/>
                ),
            },
            {
                path: "citizen/users/:id",
                element: (
                    <ProtectedRoute
                        element={<CitizenUserRoute/>}
                        roles={citizenRoutesRole}/>
                ),
            },
            {
                path: "citizen/users/:citizenId/notifications",
                element: (
                    <ProtectedRoute
                        element={<CitizenNotificationArchive/>}
                        roles={citizenRoutesRole}/>
                ),
            },
            {
                path: "citizen/users/:citizenId/notifications/:id",
                element: (
                    <ProtectedRoute
                        element={<CitizenNotificationDetails/>}
                        roles={citizenRoutesRole}/>
                ),
            },
            {
                path: "citizen/users/:citizenId/notifications/new",
                element: (
                    <ProtectedRoute
                        element={<CitizenNotificationSend/>}
                        roles={citizenRoutesRole}/>
                ),
            },
            {
                path: "/citizen/houses/:houseId/apartments/:apartment/users/:id",
                element: (
                    <ProtectedRoute
                        element={<CitizenUserRoute/>}
                        roles={citizenRoutesRole}/>
                ),
            },
            {
                path: "citizen/houses/:houseId/intercoms/:id",
                element: (
                    <ProtectedRoute
                        element={<IntercomDetails/>}
                        roles={citizenRoutesRole}/>
                ),
            },
            {
                path: "citizen/houses/:houseId/intercoms",
                element: (
                    <ProtectedRoute
                        element={<IntercomList/>}
                        roles={citizenRoutesRole}/>
                ),
            },
            {
                path: "citizen/houses/:houseId/gates",
                element: (
                    <ProtectedRoute
                        element={<GateList/>}
                        roles={citizenRoutesRole}/>
                ),
            },
            {
                path: "citizen/houses/:houseId/cameras",
                element: (
                    <ProtectedRoute
                        element={<CameraList/>}
                        roles={citizenRoutesRole}/>
                ),
            },
            {
                path: "citizen/houses/:houseId/cameras/:id",
                element: (
                    <ProtectedRoute
                        element={<CameraDetails/>}
                        roles={citizenRoutesRole}/>
                ),
            },
            {
                path: "citizen/houses/:houseId/notifications",
                element: (
                    <ProtectedRoute
                        element={<CitizenNotificationArchive/>}
                        roles={citizenRoutesRole}/>
                ),
            },
            {
                path: "citizen/houses/:houseId/notifications/:id",
                element: (
                    <ProtectedRoute
                        element={<CitizenNotificationDetails/>}
                        roles={citizenRoutesRole}/>
                ),
            },
            {
                path: "citizen/houses/:houseId/notifications/new",
                element: (
                    <ProtectedRoute
                        element={<CitizenNotificationSend/>}
                        roles={citizenRoutesRole}/>
                ),
            },
            {
                path: "citizen/houses/:houseId/apartments/:apartment/notifications",
                element: (
                    <ProtectedRoute
                        element={<CitizenNotificationArchive/>}
                        roles={citizenRoutesRole}/>
                ),
            },
            {
                path: "citizen/houses/:houseId/apartments/:apartment/notifications/new",
                element: (
                    <ProtectedRoute
                        element={<CitizenNotificationSend/>}
                        roles={citizenRoutesRole}/>
                ),
            },

            //Документы
            {
                path: "documents",
                element: (
                    <ProtectedRoute
                        element={<Documents/>}
                        roles={exceptEngineerRoles}/>
                ),
            },

            //Роли
            {
                path: "roles",
                element: (
                    <ProtectedRoute
                        element={<Roles/>}
                        roles={exceptEngineerRoles}/>
                ),
            },

            //Отчеты
            {
                path: "reports",
                element: (
                    <ProtectedRoute
                        element={<Reports/>}
                        roles={exceptEngineerRoles}/>
                ),
            },

            //Компании
            {
                path: "company",
                element: (
                    <ProtectedRoute
                        element={<CompanyDetails/>}
                        isAvailable={canSeeSingleCompany}/>
                )
            },
            {
                path: "companies",
                element: <ProtectedRoute element={<Companies/>} isAvailable={canSeeCompanyRoute}/>,
            },
            {
                path: "companies/add",
                element: <ProtectedRoute element={<CompanyCreation/>} isAvailable={canSeeCompanyRoute}/>
            },
            {
                path: "companies/:id",
                element: <ProtectedRoute element={<CompanyDetails/>} isAvailable={canSeeCompanySubRoute}/>
            },
            {
                path: "companies/:id/homes",
                element: <ProtectedRoute element={<Houses/>} isAvailable={canSeeCompanySubRoute}/>,
            },
            {
                path: "companies/:companyId/homes/:houseId",
                element: <ProtectedRoute element={<HouseDetails/>} isAvailable={canSeeCompanySubRoute}/>,
            },
            {
                path: "companies/:id/users",
                element: <ProtectedRoute element={<Users/>} isAvailable={canSeeCompanySubRoute}/>,
            },
            {
                path: "companies/:companyId/users/:userId/geo-history",
                element: <ProtectedRoute element={<UserGeoHistory/>}
                                         roles={[DefinedUserRole.ADMIN, DefinedUserRole.ADMIN_ETD]}/>,
            },
            {
                path: "companies/:companyId/tasks",
                element: <ProtectedRoute element={<Tasks/>} isAvailable={canSeeCompanySubRoute}/>,
            },
            {
                path: "companies/:companyId/tasks/:taskId",
                element: <ProtectedRoute element={<TaskDetails/>} isAvailable={canSeeCompanySubRoute}/>,
            },
            {
                path: "companies/:companyId/reports",
                element: <ProtectedRoute element={<Reports/>} roles={exceptEngineerRoles}/>,
            },
            {
                path: "companies/:companyId/documents",
                element: <ProtectedRoute element={<Documents/>} roles={exceptEngineerRoles}/>,
            },
        ]
    },
]);

export const navigationItems: INavigationItem[] = [
    {
        title: "Заявки",
        path: "/tasks",
        icon: <IconNotebook/>,
    },
    {
        title: "ЕТД Житель",
        path: "/citizen",
        icon: <IconRequests35x35/>,
        roles: citizenRoutesRole,
    },
    {
        title: "Документы",
        path: "/documents",
        icon: <IconDocument35/>,
        roles: exceptEngineerRoles,
    },
    {
        title: "Календарь",
        path: "/calendar",
        icon: <IconCalendar28x32/>,
        roles: [DefinedUserRole.ADMIN, DefinedUserRole.ADMIN_ETD],
    },
    {
        title: "Отчёты",
        path: "/reports",
        icon: <IconTaskSquare/>,
        roles: exceptEngineerRoles,
    },
    {
        title: "Компании",
        path: "/companies",
        icon: <IconPeoples/>,
        isAvailable: canSeeCompanyRoute,
    },
    {
        title: "Компания",
        path: "/company",
        icon: <IconPeoples/>,
        isAvailable: canSeeSingleCompany,
    },
    {
        title: "Дома",
        path: "/homes",
        icon: <IconApartment/>,
    },
    {
        title: "Пользователи",
        path: "/users",
        icon: <IconMan/>,
    },
    {
        title: "Роли",
        path: "/roles",
        icon: <IconShield28x32/>,
        roles: exceptEngineerRoles,
    },
];