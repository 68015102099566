import {EventHandler, ForwardedRef, forwardRef, useEffect, useRef, useState} from "react";
import {mergeRefs} from "react-merge-refs";
import {Popup as PopupBase} from "reactjs-popup";
import {PopupActions, PopupPosition} from "reactjs-popup/dist/types";

type Props = {
    isOpened?: boolean;
    keepTooltipInside?: boolean;
    position?: PopupPosition | PopupPosition[];
    children: JSX.Element;
    trigger: JSX.Element | ((isOpen: boolean) => JSX.Element);
    onOpen?(): void;
    onClose?(): void;
};

export const Popup = forwardRef((props: Props, ref: ForwardedRef<PopupActions>) => {
    const {
        isOpened,
        keepTooltipInside,
        position,
        trigger,
        children,
        onOpen,
        onClose,
    } = props;

    const [isOpenInner, setIsOpenInner] = useState(false);
    const popupRef = useRef<PopupActions>();
    const triggerRef = useRef<HTMLSpanElement>(null);
    useEffect(() => {
        if (isOpenInner) {
            const rootElem = document.getElementById("root");
            if (!rootElem) {
                console.error("[Popup] Can't find root element");
                return;
            }

            const eventListener: EventHandler<any> = e => {
                const triggerElem = triggerRef.current;
                if (triggerElem && e.target.contains(triggerElem)) {
                    popupRef.current?.close();
                }
            };
            rootElem.addEventListener("scroll", eventListener, true);
            return () => rootElem.removeEventListener("scroll", eventListener, true);
        }
    }, [isOpenInner, triggerRef]);

    const handleOpen = () => {
        setIsOpenInner(true);
        onOpen?.();
    };
    const handleClose = () => {
        setIsOpenInner(false);
        onClose?.();
    };
    return (
        <PopupBase
            ref={mergeRefs([ref, popupRef])}
            open={isOpened}
            arrow={false}
            keepTooltipInside={keepTooltipInside}
            position={keepTooltipInside ? position || ["bottom center", "bottom right", "bottom left"] : undefined}
            trigger={
                //Библиотека переопределяет ref для триггера, поэтому нужно две обертки
                typeof trigger === "function"
                    ? (isOpen) => <span><span ref={triggerRef}>{trigger(isOpen)}</span></span>
                    : <span><span ref={triggerRef}>{trigger}</span></span>
            }
            onOpen={handleOpen}
            onClose={handleClose}>
            {children}
        </PopupBase>
    );
});