import classNames from "classnames";
import {ReactNode} from "react";
import Skeleton from "react-loading-skeleton";
import {RadioButton} from "../radio_button";
import s from "./SuggestionsPopup.module.css";

interface IProps {
    children: string;
    heading?: ReactNode;
    trailing?: ReactNode;
    caption?: ReactNode;
    isSelected?: boolean;
    showIndicator?: boolean;

    onClick(): void;
}

export function Suggestion(props: IProps) {
    const {children, caption, trailing, heading, isSelected = false, showIndicator, onClick} = props;

    return (
        <li className={classNames(s.suggestion, s.suggestionInteractive)} onClick={onClick}>
            {heading}
            {showIndicator && (
                <RadioButton
                    isSelected={isSelected}
                    onToggle={onClick}/>
            )}
            <div className={s.suggestionContent}>
                <p className={s.suggestionText}>{children}</p>
                {caption}
            </div>
            {trailing}
        </li>
    );
}

export function SuggestionSkeleton() {
    return (
        <li className={s.suggestionBase}>
            <Skeleton containerClassName={s.suggestionSkeleton} height={14} borderRadius={5}/>
        </li>
    );
}
